import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "2019 年度新卒エンジニア研修について",
  "date": "2019-12-16T07:59:47.000Z",
  "slug": "entry/2019/12/16/165947",
  "tags": ["medley"],
  "hero": "./2019_12_16.png",
  "heroAlt": "2019 新卒研修"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。エンジニアの平木です。`}</p>
    <p>{`メドレーでは今年度より新卒採用活動を本格化しており、今年はエンジニア 4 名が新卒社員として入社しました。`}</p>
    <p>{`現在、新卒メンバーは 6 ヶ月の開発研修が無事終了し、各部署で業務に勤しんでいますが、このエントリでは、`}<strong parentName="p">{`初めての新卒研修をどのような視点で計画・実行していったか?`}</strong>{`を書いていきたいと思います。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "2019 年度新卒入社メンバー"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20191216/20191216115245.jpg",
        "alt": "20191216115245.jpg"
      }}></img><figcaption parentName="figure">{`2019 年度新卒入社メンバー`}</figcaption></figure>
    <h1>{`新卒研修を決めるまでやったこと`}</h1>
    <h2>{`研修の大枠決定`}</h2>
    <p>{`カリキュラムの大枠を決めるため、まず CTO の平山が「こんなことをやろう」という大枠の方向性を出し、施策レベルに筆者が落としこんでいきました。`}</p>
    <p>{`決めたことは、以下３点です。`}</p>
    <ul>
      <li parentName="ul">{`入社後の導入部分`}
        <ul parentName="li">
          <li parentName="ul">{`社会人としてのビジネスマナーや考え方の基礎を学ぶ`}</li>
          <li parentName="ul">{`プロダクト開発をする上で必要になる基礎を学ぶ`}</li>
          <li parentName="ul">{`会社の事業内容を理解する`}</li>
        </ul>
      </li>
      <li parentName="ul">{`OJT をメインとした各チームへの仮配属`}</li>
      <li parentName="ul">{`新卒社員へのフォローアップ`}
        <ul parentName="li">
          <li parentName="ul">{`メンターや部長との定期報告会`}</li>
          <li parentName="ul">{`半年後に役員陣への成果報告会`}</li>
        </ul>
      </li>
    </ul>
    <p>{`エンジニアとして価値を出していくため必要な`}<strong parentName="p">{`「社会の課題を解決するために、日々自身の腕を磨き、純粋に取り組む、ただそれだけ」`}</strong>{`という、メドレーが求めるエンジニアとしての姿勢をきちんと体得してもらうことが目標です。`}</p>
    <p>{`こちらについては平山のブログに詳しく書かれているので、ぜひご参照ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://toppa.medley.jp/05.html"
      }}>{`https://toppa.medley.jp/05.html`}</a></p>
    <h2>{`情報収集`}</h2>
    <h3>{`社内エンジニアへのヒアリング`}</h3>
    <p>{`前職で新卒研修を受けたことがある社内の 20 代~30 代のエンジニア 4 人を対象に、研修内容についてヒアリングしました。`}</p>
    <p>{`ヒアリングしたポイントは`}</p>
    <ul>
      <li parentName="ul">{`研修の期間`}</li>
      <li parentName="ul">{`研修全体の流れ`}</li>
      <li parentName="ul">{`各研修メニューの内容`}</li>
      <li parentName="ul">{`OJT はどういった形で受けたか`}</li>
      <li parentName="ul">{`研修を受けての感想`}</li>
    </ul>
    <p>{`など。各社・各年代で個性が出るなという印象を持ちました。`}</p>
    <p>{`やはり、エンジニアということで座学よりも手を動かすハンズオン形式の方が印象に残ることや、印象的な研修メニューは時間が経っても覚えているという傾向が分かりました。`}</p>
    <h3>{`ネットでの情報収集`}</h3>
    <p>{`ご存知の通り、近年では新卒研修のブログや、学習プログラムに関しては多くの企業様が書かれています。`}</p>
    <p>{`しかし、内容としては`}<strong parentName="p">{`「アプリ開発をこのような形式で行ないました」という情報が多く、研修全体や社内情勢も踏まえた背景などが書かれているものは意外に少ないな`}</strong>{`という印象を持ちました。`}</p>
    <p>{`そんな中、各社の新卒研修エントリが Gist にまとめられているのを発見しました。
こちらは大変参考になりました。作者の方にはこの場をお借りして感謝させていただきます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://gist.github.com/gcchaan/02f4746a323acac4095c30e0783a3912"
      }}>{`https://gist.github.com/gcchaan/02f4746a323acac4095c30e0783a3912`}</a></p>
    <h1>{`研修メニュー決定`}</h1>
    <h2>{`研修の詳細メニュー`}</h2>
    <p>{`ここまでの過程を踏まえ研修メニューを筆者が考え、CTO や開発部部長の田中・副部長の稲本と刷り合わせをしていきました。`}</p>
    <p>{`結果として、以下のメニュー構成でいくことになりました。`}</p>
    <ul>
      <li parentName="ul">{`オリエンテーション`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[座学]`}</strong>{`社会人研修や会社全般の知識の習得を目的にする`}</li>
        </ul>
      </li>
      <li parentName="ul">{`開発基礎研修`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[座学]`}</strong>{`Web アプリケーション開発の基本や、エンジニアとしての心得、会社の展開するサービスの基本知識を習得することを目的にする`}</li>
        </ul>
      </li>
      <li parentName="ul">{`開発実践研修`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[OJT]`}</strong>{`社内向けに Web アプリケーションを開発し、チーム開発を実践することを目的にする`}</li>
        </ul>
      </li>
      <li parentName="ul">{`開発部 OJT`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[OJT]`}</strong>{`稼動しているサービスの開発を通して、業務としての開発を実践することを目的にする`}</li>
        </ul>
      </li>
      <li parentName="ul">{`事業部 OJT`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[OJT]`}</strong>{`ビジネスサイドの業務を体験し、開発以外の会社の業務の全体像を理解することを目的にする`}</li>
        </ul>
      </li>
    </ul>
    <p>{`特に事業部 OJT は新卒メンバーには必ず理解してもらいたい、と決まった研修項目です。`}</p>
    <p>{`メドレーではプロダクトファーストで開発しますが、プロダクトの機能や要望は実際のビジネスの流れと密接に関わっています。`}</p>
    <p>{`新卒メンバーもこういった流れを把握出来ていないと、要点を押さえた開発ができません。`}<strong parentName="p">{`実際にどのような流れで顧客と関わっていき、自分達が開発するプロダクトにどう影響していくかを体験`}</strong>{`してもらうために、この事業部 OJT をメニューに組み込みました。`}</p>
    <h1>{`研修期間`}</h1>
    <p>{`研修の期間についても様々な意見が出ましたが、最終的には配属後の業務にスムーズに入ってもらえるように、基礎を重視して長めに研修時間を取ったほうがよいと決め、以下の通り全 6 ヶ月という研修期間を定めました。`}</p>
    <ul>
      <li parentName="ul">{`オリエンテーション・開発基礎研修・開発実践研修`}
        <ul parentName="li">
          <li parentName="ul">{`2 ヶ月`}</li>
          <li parentName="ul">{`期間中、午前：開発基礎、午後：開発実践`}</li>
        </ul>
      </li>
      <li parentName="ul">{`開発部 OJT ・事業部 OJT`}
        <ul parentName="li">
          <li parentName="ul">{`4 ヶ月`}</li>
          <li parentName="ul">{`事業部 OJT は期間中に 2 週間 x 2 つの事業部で実施`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`メンター`}</h1>
    <p>{`研修全体の統括は筆者になりますが、新卒メンバーがいつでも相談できるように 1 人につき 1 人のメンターを付けています。社会人経験が 3 年以上ある社員をメンターとしました。`}</p>
    <p>{`最初の 2 週は毎日 1on1 を 15 分程度行ない、以降は毎週金曜日に 1 時間の 1on1 を実施。
また、メンター同士の交流として、2 週に 1 度メンターの共有会を開き、相談や近況報告を行いました。`}</p>
    <p>{`メンター制自体も初めての試みだったためメンター同士の対応内容や新卒全員の様子が共有される場作りは、実施してよかったです。`}</p>
    <h1>{`研修メニュー内容`}</h1>
    <p>{`各研修内容のご紹介です。`}</p>
    <h2>{`オリエンテーション`}</h2>
    <p>{`オリエンテーションは`}</p>
    <ul>
      <li parentName="ul">{`外部ビジネス研修`}</li>
      <li parentName="ul">{`コンプライアンス研修`}</li>
      <li parentName="ul">{`セキュリティ研修`}</li>
      <li parentName="ul">{`開発環境の整備`}</li>
      <li parentName="ul">{`開発ツールの解説`}</li>
    </ul>
    <p>{`がメインです。`}</p>
    <p>{`Mac を渡されて、「勝手に開発環境作ってね」というのは新卒エンジニアにはハードルが高いので、筆者が付きっきりで時間をかけてフォローしました。`}</p>
    <p><strong parentName="p">{`オリエンテーションは約 1 週間程度ですが、ここで「メドレー社員の一人になった」という実感を持ってもらう`}</strong>{`ことができました。`}</p>
    <h2>{`開発基礎研修`}</h2>
    <p>{`大きく 2 部構成です。最初の部では、メドレーでエンジニアに期待していることや、既存サービスなどのシステム構成・ビジネスの流れなどを座学で実施しました。`}</p>
    <p>{`もう一つの部では書籍の輪読をしました。この輪読は、Web サービスの基本の仕組みと、Web サーバ自体を支える OS である Linux の基本を覚えてもらいたいという趣旨で開催しました。`}</p>
    <h3>{`メドレーについて`}</h3>
    <p>{`こちらの部では CTO 平山から「メドレーのエンジニアとして求めること」と題して、求められるエンジニアリングとは何なのか?自分達のキャリアパスなどをディスカッションしました。`}</p>
    <p>{`その後は、`}<strong parentName="p">{`ジョブメドレー`}</strong>{`・`}<strong parentName="p">{`CLINICS`}</strong>{`・`}<strong parentName="p">{`介護のほんね`}</strong>{` などメドレーが提供しているプロダクトについて、システムの概要やビジネスモデルとシステムの対応について講義。`}</p>
    <p>{`メドレーでのエンジニアは各々専門性がある技術領域を持ちつつ、その領域に限定せずに広い分野で開発をしていますし、プロダクトを第一に考え開発しますが、そのために技術を武器としていくバランス感覚が必要になります。`}</p>
    <p>{`この段階でまずこうした`}<strong parentName="p">{`メドレーでエンジニアとして働くとはどういうことなのか`}</strong>{`という知識や考え方を新卒メンバーに勉強してもらいました。
エンジニアとは何か
エンジニアの価値は何か
プロエンジニアとは何か
エンジニアではない職種との違いは何か
といった基本的な話から、
2030 年のみんなはどうなっているのか、どうなっていたいのか
先輩エンジニアはどのようなことに悩み、どのようにアプローチをして、力をつけてきたのか、それを超えるためのヒントはどこにあるか
メドレーのエンジニアとして求めたいことは何か
といった踏み込んだ話も含め、個人ワークやグループワークも交えながら伝えていきました。`}</p>
    <p>{`導入があることで、これからのカリキュラムを実施していく上での心構えができる期間だったのではないかと思います。`}</p>
    <h3>{`輪読会`}</h3>
    <p>{`座学でプログラミングのことを勉強させるのではなく、主体性を持ち、基礎知識を習得してほしいと考えていたので、`}<strong parentName="p">{`プランを作っている初期段階から輪読会をすることを決めていました。`}</strong>{`
参考にさせていただいたのは`}<a parentName="p" {...{
        "href": "https://ch.nicovideo.jp/dwango-engineer/blomaga/ar1054007"
      }}>{`ドワンゴさんの 2016 年の研修の記事`}</a>{`です。`}</p>
    <p>{`輪読で使用する本の選定は悩んだのですが、弊社で使っている Ruby や Ruby on Rails のことや、JavaScript などの技術より、基本となる Web 自体についての知識や、Web サーバで使う Linux の事などを習得してもらいたいということを考えて以下の 2 冊で輪読を行いました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gihyo.jp/magazine/wdpress/plus/978-4-7741-4204-3"
        }}>{`Web を支える技術 ── HTTP，URI，HTML，そして REST（WEB`}{`+`}{`DB PRESS plus シリーズ）`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lambdanote.com/products/go"
        }}>{`Go ならわかるシステムプログラミング`}</a></li>
    </ul>
    <p>{`「Web を支える技術」の輪読の形式は毎回決まった章を筆者が講義しつつ、時折質問などをメンバーに投げかけたりしながら理解を深めました。`}</p>
    <p>{`議事録も新卒メンバー全員で順番を決めてもらうという、自主性に基づくスタイルにしました。`}<strong parentName="p">{`まず輪読の雰囲気になれてもらうこと、それから議事録を取りつつドキュメンテーションの練習をすること`}</strong>{`を狙いとしています。`}</p>
    <p>{`最初はぎこちなかった部分もありましたが、回を重ねるにつれて、自分の経験や知識も含めたディスカッションにも発展するようになり、全員自主性を持ちながら実施できました。`}</p>
    <p>{`対して「Go ならわかるシステムプログラミング」については`}<strong parentName="p">{`新卒メンバーに章を割り当てて、各自講師をしてもらいました。`}</strong></p>
    <p>{`元々レポートを 3 回発表することを予定していたので、本の内容を読んできちんと理解した上で、`}<strong parentName="p">{`他人へ伝える力を養う`}</strong>{`ことをサブ目標にしています。`}</p>
    <p>{`さらなる自主性も必要になる上、本の性質上ほとんどのメンバーが触っていない Go 言語を使ってプログラムを書いてシステムのことを理解しないといけない為、プログラムの本質的な部分に触れることもできたのではないかと思います。`}</p>
    <h2>{`開発実践`}</h2>
    <p>{`開発実践は上記の開発基礎と並行して午後から実施しました。
1 日の午前が開発基礎、午後が開発実践というスケジュールです。`}</p>
    <p>{`実践は新卒メンバーで社内ツールを実際の業務形式で開発してもらいました。`}</p>
    <p>{`題材は`}<strong parentName="p">{`「メドレーの使い方に合ったビルの予約システムを作る」`}</strong>{`というものです(以前 SmartHR さんが同じビルだったので少しアプローチが違いますが`}<a parentName="p" {...{
        "href": "https://tech.smarthr.jp/entry/2019/06/28/134701"
      }}>{`ブログ`}</a>{`に書いてました)。`}</p>
    <p>{`メドレーでの業務開発と同じ形式で`}</p>
    <ul>
      <li parentName="ul">{`ユーザーからの要望をヒアリング`}</li>
      <li parentName="ul">{`要件定義`}</li>
      <li parentName="ul">{`開発計画の策定`}</li>
      <li parentName="ul">{`システム設計`}</li>
      <li parentName="ul">{`チームで分担してながらの開発`}</li>
      <li parentName="ul">{`ベータ版としてリリースしたものをブラッシュアップ`}</li>
      <li parentName="ul">{`実稼働するための環境整備をしながらリリースする`}</li>
    </ul>
    <p>{`という一連のプロセスで開発しました。`}</p>
    <p>{`「開発自体どうやって進めるか」「仕様をどうするか」など基本的な所から、全行程を新卒メンバー主体で考えて実行してもらい、メンター陣は所々 MTG や PR をレビューしながら、アドバイスをするという形式で行いました。`}</p>
    <p>{`新卒メンバー全員が同じスキルセットを持っているわけでもないですし、得意・不得意も違う為、まず最初に彼ら自身にリーダーを決めてもらい、リーダーがオーナーシップを持って開発を進めてもらいました。`}</p>
    <p>{`チームでの開発というのはほとんど全員が初めてだったため、かなり困惑しているところもありましたが、研修の過程で理解した互いのスペシャリティを活かして、役割分担をしつつ、決まった納期にきちんとリリースすることができました。メンターとしてそばで見ていましたが、新卒メンバー全員の成長を実感しましたし、純粋に凄いと感じました。`}</p>
    <p>{`途中で納期に間に合わせるための要件の取捨選択をしなければならなかったり、自分達が考えた仕様が必ずしもベストというわけではなく、さらに色々と考える要素が必要だったりという`}<strong parentName="p">{`開発のリアルを経験できた`}</strong>{`のは次の OJT に活きたと思います。`}</p>
    <p>{`余談ですが、こちらの元々のシステムを調査する上で「Web を支える技術」の知識が早速役に立つという場面が多く、やはり`}<strong parentName="p">{`基本は大事だな、と思った`}</strong>{`次第です。`}</p>
    <h2>{`開発部 OJT`}</h2>
    <p>{`基礎研修が終わってから、既存サービスにジョインしての OJT 実施となりました。`}</p>
    <p>{`ここまでの研修で、ある程度の知識や業務の進め方などは習得していたので、何をすればよいか分からないということはありませんでした。`}</p>
    <p>{`メンバーによってはここで時間を取って Ruby on Rails について習得をしてもらいました。
大きい失敗なども特にはなく粛々と進められたのは良かったのではないかと思います。`}</p>
    <h2>{`事業部 OJT`}</h2>
    <p>{`開発部 OJT の途中で 2 週間ずつ時間を取って、人材プラットフォーム事業と医療メディア事業での事業部 OJT を行いました。`}</p>
    <p><strong parentName="p">{`どこで顧客とコンタクトし、お金をいただき、どのような形で自分達が作ったシステムに関わっていくのか?`}</strong>{`というのをこの時期に体験してもらうことが目的です。`}</p>
    <p>{`研修はビジネスの流れに沿って行ないました。研修メニューは、事業部の主要メンバーにコンセプトの共有をし、メニューを考えて実施してもらいました。（ありがとうございます！）`}</p>
    <p>{`実際に電話でアポイントを取ったり、顧客のサポートを電話やビデオチャットでしたり、営業をどうやっているのかを体験したり…などを 2 週間かけて新卒メンバーに体験してもらいました。`}</p>
    <p>{`この後に実際`}<strong parentName="p">{`「このシステムの機能はこういうときに使うのかと実感した」とか「やっていてこのような仕様のほうが良いと思った」などの感想`}</strong>{`をそれぞれのメンバーが持っていて、やはり実施して良かったなと強く感じました。`}</p>
    <h2>{`レポート発表`}</h2>
    <p>{`それぞれの研修の終わりにはレポート発表の時間を設け、一人一人振り返りを書いてもらいます。その為に週報の提出も義務付けました。また研修期間の終了時には弊社役員陣にプレゼンする最終レポート発表の時間を取りました。`}</p>
    <p>{`ひたすら研修内容をこなしていくという形になりがちなので、`}<strong parentName="p">{`振り返りの期間を設けて記録を付けていき、自分の成長や反省などを可視化したい`}</strong>{`という目的です。`}</p>
    <p>{`また、メドレーでは`}<strong parentName="p">{`ドキュメントドリブンで開発に限らず全社員が Confluence でドキュメントを書いていく文化`}</strong>{`なので、文書を誰にでも分かるように論理的に書く技術が求められます。こういったレポートの記述やそのフィードバックから文書技術を高めてほしいという目的もありました。`}</p>
    <p>{`レポートに関してはメンター陣やマネージャ陣に発表してもらうという形にしていたので、発表自体やレポートのまとめ方などに都度フィードバックをするようにしました。`}</p>
    <p><strong parentName="p">{`最初はレポートが読みづらいなどもありましたが、回を重ねるごとに段々洗練されたレポートや発表になってきたのが印象的`}</strong>{`です。`}</p>
    <p>{`最終レポートは代表を始めとした経営陣に向けてレポートを発表してもらいました。今まで自分達がやってきた全ての業務を、前提の知識がない聴衆に向けて発表するということで、各自が趣向を凝らしての発表になり、経営陣からも高評価をもらっていました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`メドレーで初の新卒研修は以上のような形で終わることができました。`}</p>
    <p>{`かなりのハードスケジュールだったとか、開発部 OJT をもう少し現場と色々と話しあったほうが効果的だったかもなど反省点もありますが、現在新卒メンバーが 10 月から実際の業務で活躍しているところを見ているので、ある程度の成功を収めることができたのではないかと思います。`}</p>
    <p>{`来年度のメニューはまた違ったものになる可能性がありますが、今年の研修でも重視した`}<strong parentName="p">{`「メドレーでエンジニアとして働くことに対する意義を感じながら業務をしてもらう」`}</strong>{`という部分はズラさずに実施していければと思います。`}</p>
    <p>{`長々とお付き合いいただきありがとうございました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      